<template>
  <svg class="icon icon-search" xmlns="http://www.w3.org/2000/svg" width="11.671" height="11.671" viewBox="0 0 11.671 11.671">
    <g id="icon-search" data-name="Icon feather-search" transform="translate(-4 -4)">
      <path id="Path_1" data-name="Path 1" class="cls-1" :style="style"
            d="M13.8,9.151A4.65,4.65,0,1,1,9.15,4.5a4.65,4.65,0,0,1,4.65,4.65Z"/>
      <path id="Path_2" data-name="Path 2" class="cls-1" :style="style" d="M27.5,27.5l-2.529-2.529"
            transform="translate(-12.54 -12.54)"/>
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconSearch = Vue.extend({
  name: 'icon-search',
  props: {
    color: {
      type: String,
      default: '#000',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    style() {
      return `stroke: ${this.color};`;
    },
  },
});

export default IconSearch;
</script>
<style scoped lang="scss">
  /* stylelint-disable */
  .cls-1 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
</style>
