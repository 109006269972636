


import Component from 'vue-class-component';
import { Location } from 'vue-router';
import { Vue, Watch } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { Moment } from 'moment';
import { required } from 'vuelidate/lib/validators';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import FormInputText from '@/_modules/controls/components/form-input-text/form-input-text.vue';
import { mapGetters } from 'vuex';
import { TContact } from '@/_types/contact.type';
import IconSearch from '@/_modules/icons/components/icon-search.vue';
import UtilsHelper from '@/_helpers/utils.helper';
import Avatar from '@/_components/avatar/avatar.vue';
import { TVuelidateRuleSet } from '@/_types/vuelitation-rule-set.type';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import { TFile } from '@/_types/file.type';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import promoProgramApi, {
  TCreateConferenceProgramParams,
  TPatchConferenceProgramParams
} from '@/_modules/promo-program/api/promo-program.api';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TEvent } from '@/_types/event.type';
import EventHelper from '@/_helpers/event.helper';
import { TimeStatus } from '@/_types/time-status.enum';

const CONTACT_SEARCH_LIMIT = 5;
const CONTACT_SEARCH_DEBOUNCE_TIME = 1000;

type TConferenceProgramFormData = {
  date: Date;
  timeStart: Date;
  timeEnd: Date;
  title: string;
  speakers: number[];
  isLiveChatOn: boolean;
  isSpeakerChatOn: boolean;
  posterFile: TFile;
  description: string;
  videoStreamEmbed: string;
  vodFile: TFile;
  files: TFile[];
}

@Component({
  name: 'cabinet-program-form',
  components: {
    IconArrowLeft,
    FormInputText,
    IconSearch,
    Avatar,
    FormFileUploader,
  },
  computed: {
    ...mapGetters({
      isEventContactsLoading: 'promoContactsStore/isLoading',
      eventContacts: 'promoContactsStore/contacts',
      contact: 'promoPageStore/contact',
      lastError: 'promoProgramStore/lastError',
      getProgramById: 'promoProgramStore/getProgramById',
      event: '_eventStore/event',
    }),
  },
})
export default class CabinetProgramForm extends Vue {

  public readonly CONTACT_SEARCH_LIMIT: typeof CONTACT_SEARCH_LIMIT = CONTACT_SEARCH_LIMIT;
  public readonly isEventContactsLoading: boolean;
  public readonly eventContacts: TContact[];
  public readonly contact: TContact;
  public readonly lastError: Error;
  public readonly getProgramById: (programId: number) => TConferenceProgram;
  public readonly event: TEvent;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TConferenceProgramFormData> = {
    formData: {
      date: {
        required,
      },
      timeStart: {
        required,
        timeOrder: (): boolean => {
          return this.$moment(this.formData.timeStart).isBefore(this.$moment(this.formData.timeEnd));
        },
      },
      timeEnd: {
        required,
      },
      title: {
        required,
      },
      speakers: {},
      isLiveChatOn: {},
      isSpeakerChatOn: {},
      posterFile: {},
      description: {},
      videoStreamEmbed: {},
      vodFile: {},
      files: {},
    }
  }

  public get mode(): 'create' | 'edit' {
    return (
      this.$route.name === 'promo-page-cabinet-program-create'
      || this.$route.name === 'promo-page-cabinet-program-date-create'
    ) ? 'create' : 'edit';
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get date(): string {
    return this.$route.params.date || null;
  }

  public get programId(): number {
    return (this.$route.params.programId && parseInt(this.$route.params.programId, 10)) || null;
  }

  public get backLocation(): Location {
    const date = (this.dateMoment && this.dateMoment.format(DateTimeFormat.YYYY_MM_DD)) || this.date;
    if (date) {
      return {
        name: 'promo-page-cabinet-program-date',
        params: {
          date
        }
      };
    } else {
      return {
        name: 'promo-page-cabinet-program',
      };
    }
  }

  public get program(): TConferenceProgram {
    if (!this.programId) {
      return null;
    }
    return this.getProgramById(this.programId);
  }

  public get conferenceId(): number {
    if (this.mode === 'create') {
      return (this.$route.params.conferenceId && parseInt(this.$route.params.conferenceId, 10)) || null;
    } else {
      return null;
    }
  }

  public get dateMoment(): Moment {
    if (this.mode === 'create') {
      return this.$route.params.date ? this.$moment(this.$route.params.date) : null;
    } else {
      return null;
    }
  }

  public get selectedSpeakers(): TContact[] {
    return this.formData.speakers
      .map(speakerId => (this.contactsMap.get(speakerId) || null))
      .filter(speaker => !!speaker);
  }

  public get isLoading(): boolean {
    return this.isEventContactsLoading;
  }

  public get meetingRoomConfig(): TMeetingRoomConfig {
    if (!this.eventId || !this.contact || !this.contact.id) {
      return null;
    }

    return {
      type: MeetingRoomType.BROADCAST,
      broadcastType: BroadcastType.PROGRAM_SPEAKER,
      eventId: this.eventId,
      contactId: this.contact.id,
      programId: this.programId,
    };
  }

  public fullEventContacts: TContact[] = [];
  public formData: TConferenceProgramFormData = {
    date: null,
    timeStart: null,
    timeEnd: null,
    title: '',
    speakers: [],
    isLiveChatOn: true,
    isSpeakerChatOn: true,
    posterFile: null,
    description: '',
    videoStreamEmbed: null,
    vodFile: null,
    files: [],
  };
  public contactsMap: Map<number, TContact> = new Map<number, TContact>();
  public isContactSearchInputFocused: boolean = false;
  public foundContacts: TContact[] = [];
  public isSearchedThroughAll: boolean = true;
  public isSearching: boolean = false;
  public searchString: string = '';
  public isSearchResultsVisible: boolean = false;
  public isProcessing: boolean = false;

  private destroyed$: Subject<void> = new Subject<void>();
  private contactsSearch$: Subject<void> = new Subject<void>();

  public created(): void {
    this.contactsSearch$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(CONTACT_SEARCH_DEBOUNCE_TIME),
    ).subscribe(() => {
      this.updateFoundContacts();
    });

    this.subscribeToPageEvents();
  }

  public activated(): void {

    if (this.mode === 'create') {
      if (!this.conferenceId || !this.dateMoment) {
        this.$router.push(this.backLocation);
        return;
      }
    } else {
      if (!this.programId || !this.program) {
        this.$router.push(this.backLocation);
        return;
      }
    }

    this.initFormData();
    this.contactsSearch$.next();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public onBroadcastChoiceEmbedClick(): void {
    const meetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setEmbedCodeDialogConfig', meetingRoomConfig);
  }

  public onBroadcastChoiceZoomClick(): void {
    const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
      return;
    }
    const meetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', meetingRoomConfig);
  }

  public onBroadcastChoiceOBSClick(): void {
    const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);
    if (eventTimeStatus === TimeStatus.PAST) {
      this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', true);
      return;
    }
    const meetingRoomConfig = this.meetingRoomConfig;
    if (!meetingRoomConfig) {
      return;
    }
    this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', meetingRoomConfig);
  }

  public onContactSearchInputFocusIn(): void {
    this.isSearchResultsVisible = true;
  }

  public onFoundContactClick(contact: TContact): void {
    if (this.formData.speakers.indexOf(contact.id) < 0) {
      this.formData.speakers.push(contact.id);
      this.$v.formData.speakers.$touch();
    }
  }

  public onRemoveSpeakerClick(speaker: TContact): void {
    this.formData.speakers.splice(this.formData.speakers.indexOf(speaker.id), 1);
    this.$v.formData.speakers.$touch();
  }

  public onRemoveFileClick(file: TFile): void {
    this.formData.files.splice(this.formData.files.indexOf(file), 1);
    this.$v.formData.files.$touch();
  }

  public onLiveChatSwitchLabelClick(): void {
    this.formData.isLiveChatOn = !this.formData.isLiveChatOn;
    this.$v.formData.isLiveChatOn.$touch();
  }

  public onSpeakerChatSwitchLabelClick(): void {
    this.formData.isSpeakerChatOn = !this.formData.isSpeakerChatOn;
    this.$v.formData.isSpeakerChatOn.$touch();
  }

  public onSearchInputClick(event: MouseEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  public async onSubmitClick(): Promise<void> {
    this.$v.formData.$touch();
    if (this.$v.formData.$invalid) {
      return;
    }

    this.isProcessing = true;

    // TODO: split code in methods!

    if (this.mode === 'create') {
      const createConferenceProgramParams: TCreateConferenceProgramParams = this.formData2CreateConferenceProgramParams();
      let newConferenceProgram: TConferenceProgram;
      try {
        newConferenceProgram = await promoProgramApi.createConferenceProgram(createConferenceProgramParams);
      } catch (error) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }

      if (!newConferenceProgram) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }

      try {
        const promises: Promise<void>[] = [];
        for (let i = 0; i < this.formData.speakers.length; i++) {
          promises.push(
            promoProgramApi.addConferenceProgramSpeaker({
              event_id: this.eventId,
              conference_id: this.conferenceId,
              program_id: newConferenceProgram.id,
              contact_id: this.formData.speakers[i],
            })
          );
        }
        for (let i = 0; i < this.formData.files.length; i++) {
          promises.push(
            promoProgramApi.addConferenceProgramFile({
              event_id: this.eventId,
              conference_id: this.conferenceId,
              program_id: newConferenceProgram.id,
              url: this.formData.files[i].url,
              filename: this.formData.files[i].filename,
            })
          );
        }
        await Promise.all(promises);
      } catch (error) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }

    } else if (this.mode === 'edit') {
      const program = this.program;
      const patchConferenceProgramParams: TPatchConferenceProgramParams = this.formData2PathcConferenceProgramParams();
      try {
        await promoProgramApi.patchConferenceProgram(patchConferenceProgramParams);
      } catch (error) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }

      try {
        const promises: Promise<void>[] = [];

        const oldSpeakersIds: number[] = (program.speakers || []).map(speaker => speaker.id);
        for (let i = 0; i < this.formData.speakers.length; i++) {
          if (oldSpeakersIds.indexOf(this.formData.speakers[i]) < 0) {
            promises.push(
              promoProgramApi.addConferenceProgramSpeaker({
                event_id: this.eventId,
                conference_id: program.conference_id,
                program_id: program.id,
                contact_id: this.formData.speakers[i],
              })
            );
          }
        }
        for (let i = 0; i < oldSpeakersIds.length; i++) {
          if (this.formData.speakers.indexOf(oldSpeakersIds[i]) < 0) {
            promises.push(
              promoProgramApi.deleteConferenceProgramSpeaker({
                event_id: this.eventId,
                conference_id: program.conference_id,
                program_id: program.id,
                contact_id: oldSpeakersIds[i],
              })
            );
          }
        }

        const oldFilesIds: number[] = (program.files || []).map(file => file.id);
        const newFilesIds: number[] = (this.formData.files || []).map(file => (file.id || null));
        for (let i = 0; i < this.formData.files.length; i++) {
          if (!this.formData.files[i].id) {
            promises.push(
              promoProgramApi.addConferenceProgramFile({
                event_id: this.eventId,
                conference_id: program.conference_id,
                program_id: program.id,
                url: this.formData.files[i].url,
                filename: this.formData.files[i].filename,
              })
            );
          }
        }
        for (let i = 0; i < oldFilesIds.length; i++) {
          if (newFilesIds.indexOf(oldFilesIds[i]) < 0) {
            promises.push(
              promoProgramApi.deleteConferenceProgramFile({
                event_id: this.eventId,
                conference_id: program.conference_id,
                program_id: program.id,
                file_id: oldFilesIds[i],
              })
            );
          }
        }

        await Promise.all(promises);
      } catch (error) {
        // TODO: ?
        this.isProcessing = false;
        return;
      }
    }

    this.isProcessing = false;

    // TODO: patch store instead of reloading, but...
    // TODO: there is no API for getting program by id, requesting all of them...
    await this.$store.dispatch('promoProgramStore/reset');
    this.$store.dispatch('promoProgramStore/loadProgram', this.eventId);

    // TODO: success indication?
    this.$router.push(this.backLocation);
  }

  private initFormData(): void {
    if (this.mode === 'create') {
      const dateMoment = this.dateMoment;
      this.formData.date = dateMoment.toDate();
      this.formData.timeStart = dateMoment.clone().hours(9).minute(0).toDate();
      this.formData.timeEnd = dateMoment.clone().hours(10).minute(0).toDate();
      this.formData.title = '';
      this.formData.speakers = [];
      this.formData.isLiveChatOn = true;
      this.formData.isSpeakerChatOn = true;
      this.formData.posterFile = null;
      this.formData.description = '';
      this.formData.videoStreamEmbed = null;
      this.formData.vodFile = null;
      this.formData.files = [];
    } else if (this.mode === 'edit') {
      const program = this.program;
      const programDateStartMoment = this.$moment(program.date_start.replace('Z', ''));
      const programDateEndMoment = this.$moment(program.date_end.replace('Z', ''));
      this.formData.date = programDateStartMoment.toDate();
      this.formData.timeStart = programDateStartMoment.toDate();
      this.formData.timeEnd = programDateEndMoment.toDate();
      this.formData.title = program.title;
      this.formData.speakers = (program.speakers || []).map(speaker => speaker.id);
      this.formData.isLiveChatOn = program.show_live_chat;
      this.formData.isSpeakerChatOn = program.show_speaker_chat;
      this.formData.posterFile = program.poster_url ? {
        url: program.poster_url,
      } : null;
      this.formData.description = program.description;
      this.formData.videoStreamEmbed = program.video_stream_embed || null;
      this.formData.vodFile = program.vod_url ? {
        url: program.vod_url,
      } : null;
      this.formData.files = program.files || [];
    }

    // console.log('--------------------------------');
    // console.log('program', this.program);
    // console.log('initFormData', this.$v.formData);
    // console.log('--------------------------------');
  }

  private formData2CreateConferenceProgramParams(): TCreateConferenceProgramParams {
    return {
      event_id: this.eventId,
      conference_id: this.conferenceId,
      title: this.formData.title,
      date_start: this.$moment(this.formData.date)
        .hours(this.formData.timeStart.getHours())
        .minute(this.formData.timeStart.getMinutes())
        .format(DateTimeFormat.API_DATE_SMALL),
      date_end: this.$moment(this.formData.date)
        .hours(this.formData.timeEnd.getHours())
        .minute(this.formData.timeEnd.getMinutes())
        .format(DateTimeFormat.API_DATE_SMALL),
      poster_url: this.formData.posterFile ? this.formData.posterFile.url : null,
      description: this.formData.description,
      video_stream_embed: this.formData.videoStreamEmbed,
      vod_url: this.formData.vodFile ? this.formData.vodFile.url : null,
      show_live_chat: this.formData.isLiveChatOn,
      show_speaker_chat: this.formData.isSpeakerChatOn,
    };
  }

  private formData2PathcConferenceProgramParams(): TPatchConferenceProgramParams {
    const program = this.program;
    const patchParams: TPatchConferenceProgramParams = {
      event_id: this.eventId,
      conference_id: program.conference_id,
      id: program.id,
    };

    if (this.formData.title !== program.title) {
      patchParams.title = this.formData.title;
    }

    // TODO: check if date was changed
    patchParams.date_start = this.$moment(this.formData.date)
      .hours(this.formData.timeStart.getHours())
      .minute(this.formData.timeStart.getMinutes())
      .format(DateTimeFormat.API_DATE_SMALL);

    // TODO: check if date was changed
    patchParams.date_end = this.$moment(this.formData.date)
      .hours(this.formData.timeEnd.getHours())
      .minute(this.formData.timeEnd.getMinutes())
      .format(DateTimeFormat.API_DATE_SMALL);

    const oldPosterUrl = program.poster_url || null;
    const newPosterUrl = this.formData.posterFile ? this.formData.posterFile.url : null;
    if (oldPosterUrl !== newPosterUrl) {
      patchParams.poster_url = newPosterUrl;
    }

    if (this.formData.description !== program.description) {
      patchParams.description = this.formData.description;
    }

    const oldVodUrl = program.vod_url || null;
    const newVodUrl = this.formData.vodFile ? this.formData.vodFile.url : null;
    if (oldVodUrl !== newVodUrl) {
      patchParams.vod_url = newVodUrl;
    }

    if (this.formData.isLiveChatOn !== program.show_live_chat) {
      patchParams.show_live_chat = this.formData.isLiveChatOn;
    }

    if (this.formData.isSpeakerChatOn !== program.show_speaker_chat) {
      patchParams.show_speaker_chat = this.formData.isSpeakerChatOn;
    }

    return patchParams;
  }

  @Watch('contact', { immediate: true })
  private onContactsChange(): void {
    this.onContactsListUpdated();
  }

  @Watch('eventContacts', { immediate: true })
  private onEventContactsChange(): void {
    this.onContactsListUpdated();
  }

  // @Watch('formData', { immediate: true, deep: true })
  // private onFormDataChange(): void {
  //   console.log('--------------------------------');
  //   console.log('onFormDataChange: ', this.formData);
  //   console.log('this.$v.formData: ', this.$v.formData);
  //   console.log('--------------------------------');
  // }

  private onContactsListUpdated(): void {
    this.foundContacts = [];
    this.fullEventContacts = [];
    this.contactsMap.clear();
    if (this.contact) {
      this.contactsMap.set(this.contact.id, this.contact);
      this.fullEventContacts.push(this.contact);
    }
    if (this.eventContacts && this.eventContacts.length) {
      this.eventContacts.forEach(contact => this.contactsMap.set(contact.id, contact));
      this.fullEventContacts = [ ...this.fullEventContacts, ...this.eventContacts ];
    }

    this.contactsSearch$.next();
  }

  private async updateFoundContacts(): Promise<void> {
    // TODO: not calling sometime?

    this.isSearching = true;
    this.foundContacts = [];
    let searchString = this.searchString.trim();
    if (searchString) {
      searchString = UtilsHelper.escapeRegExp(searchString);
    }
    const searchRegExp = new RegExp(`${searchString}`, 'i');
    const foundContacts: TContact[] = [];
    let index = 0;
    for (; index < this.fullEventContacts.length; index++) {

      // TODO: skip no names?
      if (!this.fullEventContacts[index].fullName) {
        continue;
      }

      if (searchString) {
        if (searchRegExp.exec(this.fullEventContacts[index].fullName)) {
          foundContacts.push(this.fullEventContacts[index]);
        }
      } else {
        foundContacts.push(this.fullEventContacts[index]);
      }

      if (foundContacts.length >= CONTACT_SEARCH_LIMIT) {
        break;
      }
    }

    this.foundContacts = foundContacts;
    this.isSearchedThroughAll = index >= this.fullEventContacts.length - 1;
    this.isSearching = false;
  }

  private subscribeToPageEvents(): void {
    fromEvent<MouseEvent>(document, 'click')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentClick);
  }

  private onDocumentClick(): void {
    this.isSearchResultsVisible = false;
  }

  @Watch('searchString', { immediate: true })
  private onSearchStringChange(): void {
    this.contactsSearch$.next();
  }
}
