import { render, staticRenderFns } from "./cabinet-program-form.html?vue&type=template&id=00ebae62&scoped=true&"
import script from "./cabinet-program-form.vue?vue&type=script&lang=ts&"
export * from "./cabinet-program-form.vue?vue&type=script&lang=ts&"
import style0 from "./cabinet-program-form.scss?vue&type=style&index=0&id=00ebae62&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ebae62",
  null
  
)

export default component.exports